<template lang="pug">
  section
    Card.m-b-25(:title="$t('basic info')" dis-hover)
      div(slot="extra")
        template(v-if="isEdit")
          Button(type="text" size="small" @click="changeEdit")
            span {{ $t('cancel') }}
          Button(type="text" size="small" :loading="loading" @click="submit")
            Icon(type="md-create")
            span.m-l-5 {{ $t('save') }}
        Button(type="text" size="small" @click="changeEdit" v-else)
          Icon(type="md-create")
          span.m-l-5 {{ $t('edit') }}

      Form.p-t-35.p-b-35(ref="formEdit" :model="user" :rules="formAddRuleValidate" :label-width="120")
        Row(:gutter="30")
          iCol(span="17")
            FormItem(:label="$t('realname')")
              Input(v-model="user.profile.realname" :placeholder="$t('placeholder.please enter your name')")
            FormItem(:label="$t('nicename')")
              Input(v-model="user.profile.nicename" :placeholder="$t('placeholder.please enter your nicename')")
            FormItem(:label="$t('gender')")
              RadioGroup(v-model="user.profile.gender")
                Radio(label="male") {{ $t('male') }}
                Radio(label="female") {{ $t('female') }}
                Radio(label="unknown") {{ $t('keep secret') }}
            FormItem(:label="$t('birthday')")
              DatePicker(type="date" :placeholder="$t('placeholder.please select a date')" v-model="user.profile.birthday" format="yyyy-MM-dd" @on-change="check_horoscope" style="width: 278px;")
              Input(v-model="user.profile.horoscope" :placeholder="$t('placeholder.horoscope')" readonly style="width: auto; margin-left: 15px;")
            FormItem(:label="$t('user url')")
              Input(v-model="user.profile.url")
            FormItem(:label="$t('user bio')")
              Input(v-model="user.profile.description" type="textarea" :autosize="{ minRows: 4, maxRows: 8 }")
          iCol(span="7")
            //- vcUploadWithCrop(:data="user.avatar" :options="options" @success="onSuccess")

    Card.m-b-25(:title="$t('educational info')" :padding="46" dis-hover)
      Timeline.educational-timeline
        TimelineItem
          p.time 2021年07月毕业
          strong 广东开放大学  大专  大数据技术与应用
    
    Card.m-b-25(:title="$t('career info')" :padding="46" dis-hover)
      Timeline.career-timeline
        TimelineItem
          p.time 2016年08月 - 2020年10月
          strong 深圳蒂脉时代科技控股有限公司/项目经理
          .pcontent
            p 工作内容：工作内容包含 项目前期的规划，统筹立项、团队组建；开发实施中的 设计质量把控，人机交互把控，团队分工与协调，项目进度推进等。具体的应用包含一个Saas平台及平台延申的网站、小程序、微商城、APP软件、IOT应用等。
        TimelineItem
          p.time 2016年01月 - 2016年07月
          strong 汕头市一益网络科技有限公司/游戏开发工程师
          .pcontent
            p 工作内容：主要负责移动端游戏前端开发。基于 H5 canvas 的实现及后期 CoCos 的重构。
        TimelineItem
          p.time 2012年08月 - 2015年12月
          strong 汕头市亦科网络科技有限公司/项目经理
          .pcontent
            p 工作内容：主要是负责网站设计及开发。
            p 1. 评估工作量及耗时。
            p 2. 建立及完善、迭代后台管理系统（TP+MySQL）。
            p 3. WEB 开发，PC 商城开发。
            p 4. 微信公众号接入及微信支付等。
        TimelineItem
          p.time 2006年10月 - 2010年11月
          strong 汕头市永图科技有限公司/网页设计师
          .pcontent
            p 工作内容：主要负责对网站进行设计及切片 和 制作全 Flash 网站。

    //- div {{ account }}

    //- UpdatePSW
    //- UpdateEmail
    //- UpdateMobile

    //- Card(:title="$t('user.career')" dis-hover)
    //-   div(slot="extra")
    //-     template(v-if="isEdit")
    //-       Button(type="text" size="small" @click="changeEdit")
    //-         span {{ $t('cancel') }}
    //-       Button(type="text" size="small" :loading="loading" @click="submit")
    //-         Icon(type="md-create")
    //-         span.m-l-5 {{ $t('save') }}
    //-     Button(type="text" size="small" @click="changeEdit" v-else)
    //-       Icon(type="md-create")
    //-       span.m-l-5 {{ $t('edit') }}

    //-   Form(ref="formEdit" :model="user" :rules="formAddRuleValidate")
    //-     Row(:gutter="30")
    //-       iCol(span="12")
    //-         FormItem(label="公司")
    //-           Input(v-model="user.profile.company")
    //-       iCol(span="12")
    //-         FormItem(label="职业")
    //-           Input(v-model="user.profile.job" placeholder="")
    //-       iCol(span="8")
    //-         FormItem(label="所在国家")
    //-           Input(v-model="user.profile.country" placeholder="")
    //-       iCol(span="8")
    //-         FormItem(label="所在省份")
    //-           Input(v-model="user.profile.province" placeholder="")
    //-       iCol(span="8")
    //-         FormItem(label="所在城市")
    //-           Input(v-model="user.profile.city" placeholder="")
          
</template>

<script>
// import UpdatePSW from '@/components/UpdatePSW'
// import UpdateEmail from '@/components/UpdateEmail'
// import UpdateMobile from '@/components/UpdateMobile'
import moment from 'moment'

import { mapState } from 'vuex'
//- import vcUploadWithCrop from '@/components/vcUploadWithCrop'

export default {
  name: 'ProfileView',
  components: {
    // UpdatePSW,
    // UpdateEmail,
    // UpdateMobile
    //- vcUploadWithCrop
  },
  data() {
    const validateAccount = (rule, value, callback) => {
      if (!value) return callback(new Error('账号必须'))
      if (!/^[a-zA-Z0-9_]*$/.test(value)) return callback(new Error('只允许英文字母，数字和下划线'))

      // 模拟异步验证效果
      // setTimeout(() => {
      //   if (!Number.isInteger(value)) {
      //     callback(new Error('Please enter a numeric value'));
      //   } else {
      //     if (value < 18) {
      //       callback(new Error('Must be over 18 years of age'));
      //     } else {
      //       callback();
      //     }
      //   }
      // }, 1000)
      callback()
    }
    const validateRepassword = (rule, value, callback) => {
      if (this.user.password) {
        if (value !== this.user.password) {
          callback(new Error('两次密码不一致'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      options: {
        app: 'admin',
        module: 'user',
        key: 'avatar',
        size: [ 180, 210 ],
        style: {
          borderRadius: '50%'
        }
      },

      isEdit: false,
      upload_api: `${this.$http.defaults.baseURL}attachment`,
      user: {
        profile: {},
        display_name: '',
        account: '',
        password: '',
        repassword: '',
        email: '',
        mobile: '',
        description: '',
        status: true
      },
      formAddRuleValidate: {
        account: [
          { validator: validateAccount, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码必须', trigger: 'blur' },
          { type: 'string', min: 6, message: '密码长度最少6位', trigger: 'blur' }
        ],
        repassword: [
          { validator: validateRepassword, trigger: 'blur' }
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        // birthday: [
        //   { required: true, type: 'date', message: '请选择生日', trigger: 'change' }
        // ],
        email: [
          { required: true, message: '请填写邮箱地址', trigger: 'blur' },
          { type: 'email', message: '邮箱格式错误', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { pattern: /^(0|86|17951)?(1[3-9][0-9])[0-9]{8}$/, message: '手机号码格式错误', trigger: 'blur' }
        ],
        description: [
          { type: 'string', max: 100, message: '最多100个字符', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '状态必须', trigger: 'change' }
        ]
      },
      loading: false,
      model1: '',
      data: []
    }
  },
  computed: {
    ...mapState({
      account: state => state.account,
    }),

    all_names() {
      let names = []
      if (this.user.account) names.push(this.user.account)
      if (this.user.display_name) names.push(this.user.display_name)
      if (this.user.profile && this.user.profile.nicename) names.push(this.user.profile.nicename)
      if (this.user.profile && this.user.profile.realname) names.push(this.user.profile.realname)
      return [...new Set(names)]
    }
  },
  methods: {
    /*********************************************************
     * @description 上传组件回调 删除/上传成功
     *********************************************************/
    // 删除/上传成功
    onSuccess(key, res) {
      console.log(key, res)
      // this.detail[key] = res;
    },


    changeEdit() {
      this.isEdit = !this.isEdit;
    },
    update_profile(data) {
      return this.$http.put('user/profile', data)
    },
    update_password(data) {
      return this.$http.put('user/password', data)
    },
    update_email(data) {
      return this.$http.put('user/email', data)
    },
    update_mobile(data) {
      return this.$http.put('user/mobile', data)
    },

    check_horoscope(val) {
      if (!val) return this.user.profile.horoscope = '';
      const horoscopes = [
        { label: '白羊座', date: [ 3.21, 4.19 ]},
        { label: '金牛座', date: [ 4.20, 5.20 ]},
        { label: '双子座', date: [ 5.21, 6.21 ]},
        { label: '巨蟹座', date: [ 6.22, 7.22 ]},
        { label: '狮子座', date: [ 7.23, 8.22 ]},
        { label: '处女座', date: [ 8.23, 9.22 ]},
        { label: '天秤座', date: [ 9.23, 10.23 ]},
        { label: '天蝎座', date: [ 10.24, 11.22 ]},
        { label: '射手座', date: [ 11.23, 12.21 ]},
        { label: '摩羯座', date: [ 12.22, 1.18 ]},
        { label: '水瓶座', date: [ 1.20, 2.18 ]},
        { label: '双鱼座', date: [ 2.19, 3.20 ]},
      ]
      let item = horoscopes.find(item => {
        let date_start = moment(`${moment(val).year()}.${item.date[0]} 00:00:00`, 'YYYY.MM.DD hh:mm:dd');
        let date_end = moment(`${moment(val).year()}.${item.date[1]} 23:59:59`, 'YYYY.MM.DD hh:mm:dd');
        // console.log(date_start, date_end, moment(val))
        return moment(val).isBetween(date_start, date_end)
      })
      this.user.profile.horoscope = item.label;
    },

    async submit() {
      try {
        this.loading = true;

        const gender_setter = [ 'unknown', 'male', 'female' ]
        this.user.profile.gender = gender_setter.findIndex(item => item === this.user.profile.gender)
        await this.update_profile(this.user)

        this.loading = false
        this.$message.success('更新成功')
        
        const gender_getter = [ 'unknown', 'male', 'female' ];
        this.user.profile.gender = gender_getter.find((item, index) => index === this.user.profile.gender);
      } catch (err) {
        this.loading = false
        this.$message.error(err)
      }

      // 更新密码
      // this.update_password({
      //   orgPSW: '234',
      //   newPSW: 'vvb',
      //   repPSW: 'vvb'
      // })

      // 更改邮箱
      // this.update_email({
      //   email: '52953973@qq.com'
      // })

      // 更改手机
      // this.update_mobile({
      //   mobile: '13750452550',
      //   captcha: '123456'
      // })

      // this.user.gender = this.user.sex === 'unknown' ? 0 : this.user.sex === 'male' ? 1 : 2
      // this.$http.put(`user/profile`, this.user).then(() => {
      //   this.$message.success('更新成功')
      // }).catch(err => {
      //   this.$message.error(err.message)
      // })
    },
    // 提交表单
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$http.put(`user/${this.user.id}`, this.user).then(res => {
            window.console.log(res)
            this.$message.success('修改成功!')
            this.$refs[name].resetFields()
            this.get_date()
          }).catch(err => {
            this.$message.info(err.message)
          })
        } else {
          this.$message.error('提交失败!')
        }
      })
    },
    // 重置表单
    handleReset (name) {
      this.$refs[name].resetFields()
    }
  },
  async mounted () {
    // try {
    //   this.loading = true
    //   let res = await this.get_profile()
    //   res.status = Boolean(res.status)
    //   res.profile.birthday = res.profile.birthday ? moment(res.profile.birthday).format('YYYY-MM-DD') : null;
    //   const gender_getter = [ 'unknown', 'male', 'female' ];
    //   res.profile.gender = gender_getter.find((item, index) => index === res.profile.gender);
    //   this.user = res

    //   this.loading = false
    // } catch (err) {
    //   this.loading = false
    //   this.$message.error(err)
    // }
  }
}
</script>

<style lang="less">
.educational-timeline {
  .ivu-timeline-item-content { padding-left: 32px;}

  strong { font-size: 14px;}
  .time { font-size: 14px; margin-bottom: 5px; opacity: 0.65;}
}

.career-timeline {
  .ivu-timeline-item-content { padding-left: 32px;}

  strong { font-size: 14px;}
  .time { font-size: 14px; margin-bottom: 5px; opacity: 0.65;}
  .pcontent { line-height: 1.85; margin-top: 5px; opacity: 0.75;}
}
</style>
